<template>
    <div class=container>
        <table class=table width="73%" border="0" cellspacing="30" cellpadding="0"><tbody><tr>
                  <td align="left"><div align="center"><font color="#000000">					  </font>
                    <div align="center">                      <font size="6" color="#000099"><b><font face="Verdana, Arial, Helvetica, sans-serif">
                    </font></b></font>
                      <center>
                        <font size="+2">Requesting Data </font>                        
                      </center> 
                      <font color="#000033"><br>
                      </font>
                      <div align="center"><font color="#000033"><br>
                        </font>
                          <div align="center"> </div>
                      </div>
                      <table width="85%" height="163" border="0" align="center" cellpadding="5" bgcolor="#FFFFCC">
                        <tbody><tr>
                          <td height="159"><div align="center" class="style3">
                              <p><font face="Verdana, Arial, Helvetica, sans-serif">Plase send data quest to <strong>srinivas@cs.cmu.edu</strong>.</font></p>
                          </div></td>
                        </tr>
                      </tbody></table>
 
                      <br>
                      <br>
                      <hr width="700" size="0">
                      <br>
</div>
                    <table width="100%" border="0">
  <tbody><tr>
    <td width="49%"><div align="center"><font face="Verdana, Arial, Helvetica, sans-serif" size="2">[ <router-link to="/repository/WILD">Back to WILD Home</router-link> ]</font></div></td>
  </tr>
</tbody></table>
<br>
<br>
<table width="100%" border="0" align="center">
  <tbody><tr>
    <td width="49%"><div align="center"><font color="#000033" size="2" face="Verdana, Arial, Helvetica, sans-serif">Contact: <a href="mailto:wild@cs.columbia.edu">wild@cs.columbia.edu</a></font></div></td>
  </tr>
</tbody></table>
<br>
                  </div></td>
                </tr>
        </tbody></table>
    </div>
</template>

<script>

</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin: 0 auto;
}
</style>